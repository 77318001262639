import * as React from 'react';
import { useNavigate } from 'react-router-dom';


export default function EntryPage() {
    const navigate = useNavigate();
    const navigateToHome = () => {
        navigate('/home');
    };

    React.useEffect(() => {
        setTimeout(myURL, 3000);
        function myURL() {
        
            navigateToHome()
        }

    }, [])

    return (
        <div >

            <div className="d-flex flex-wrap flex-column Entry-Logo ">
                <div className='m-4 p-3'>
                    <div className='p-2'>
                        <img width='100%' src="img/EntryLogo.gif" />
                    </div>

                </div>
            </div>
            {/* End */}

        </div>




    );
}
