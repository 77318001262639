import * as React from 'react';
import Nav from '../sharedComponents/navbar'
import Footer from '../sharedComponents/footer'
import { useNavigate } from 'react-router-dom';
import * as api from '../Apis/newsRoom';
import config from '../config/config.json'
export default function Home(props) {

    React.useEffect(() => {
        fetchData()

    }, [])
    const navigate = useNavigate();
    const [latestnews1, setLatestnews1] = React.useState([]);
    const [latestnews1Image, setLatestnews1Image] = React.useState([]);

    const [latestnews2, setLatestnews2] = React.useState([]);
    const [latestnews2Image, setLatestnews2Image] = React.useState([]);

    const fetchData = async () => {
        const result = await api.readEvents();
        if (result.data.data[0].attributes) {
            setLatestnews1(result.data.data[0].attributes)
            setLatestnews1Image(result.data.data[0].attributes.image.data[0].attributes.url)


            setLatestnews2(result.data.data[1].attributes)
            setLatestnews2Image(result.data.data[1].attributes.image.data[0].attributes.url)
        }
    }



    const navigateToAbout = () => {
        navigate('/aboutUs');
    };
    const navigateToCareer = () => {
        window.location.href='https://careers.cyshield.com/';
    };

    const navigateToCntactus = () => {
        navigate('/contactUs');
    };

    const navigateToSoftware = () => {
        navigate('/software');
    };

    const navigateToAIDS = () => {
        navigate('/AIDS');
    };

    const navigateToSOC = () => {
        navigate('/soc');
    };

    const navigateToCyberSecurity = () => {
        navigate('/cyberSecurity');
    };
    const navigateToRTS = () => {
        navigate('/rts');
    };

    const navigateToGRC = () => {
        navigate('/grc');
    };

    return (
        <body data-bs-spy="scroll" data-bs-target="#main_menu">
            <div id="home"></div>
            <Nav />

            {/* First Part*/}

            <section id="slider" className="carousel slide" data-bs-ride="carousel" data-bs-touch="true">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <section id="about" className="pb-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6 align-self-center about-text">
                                        <div className='redLine'></div>
                                        <span className="title-md" >KNOW US BETTER</span>
                                        <h6 className="title-sm">We help to create strategies,design & development</h6>
                                        <p className="mb-4 title-p" > With a supreme talented team and a constant desire for innovation
                                            we offer distinctive quality of services with the customer
                                            in the core of everything we deliver.</p>
                                        <button type="button" className="btn buttonSlider" onClick={navigateToAbout}>Know more about us</button>

                                    </div>

                                    <div className="col-md-6 align-self-center about-img">
                                        <img src="img/aboutUs.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className="carousel-item ">
                        <section id="about" className="pb-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6 align-self-center about-text">
                                        <div className='redLine'></div>
                                        <span className="title-md" > JOIN OUR TEAM</span>
                                        <h6 className="title-sm">If you are looking to grow your career! "Here We Are"</h6>
                                        <p className="mb-4 title-p" >A fast growing cutting edge technology enterprise that offers abroad support to our family
                                            members while they experience our unique culture and lifestyle.</p>
                                            
                                        <button class="buttonSlider" onClick={navigateToCareer}>Apply to a Job</button>
                                    </div>
                                    <div className="col-md-6 align-self-center about-img">
                                        <img src="img/Home1.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>


                </div>
                <button type="button" className="carousel-control-prev" data-bs-target="#slider" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon caresoulRight" ></span>
                </button>
                <button type="button" className="carousel-control-next" data-bs-target="#slider" data-bs-slide="next">
                    <span className="carousel-control-next-icon caresoulLeft"></span>
                </button>
            </section>

            {/* Latest News*/}
            <section id="latestNews" className="pb-5">
                <div className="container">
                    <div className="row">
                        {latestnews1 !== null ?
                            <div className="col-md-6 align-self-center about-text">
                                <div className='redLine'></div>
                                <span>Latest News</span>
                                <h3></h3>
                                <img src={`${config.backend.baseurl + latestnews1Image}`} alt="" />
                                <p className='pt-3'>
                                    {latestnews1.Title}
                                </p>
                            </div>
                            : ""}
                        {latestnews2 !== null ?
                            <div className="col-md-6 align-self-center about-img">
                                <img src={`${config.backend.baseurl + latestnews2Image}`} alt="" />
                                <p className='pt-3'>
                                    {latestnews2.Title}
                                </p>
                            </div> 
                            :""}

                    </div>
                </div>
            </section>

            {/* Services Part*/}
            <section id="serviceHome" className="pb-5">
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-md-8 offset-md-2 text-center">
                            <h2 className="title-md">OUR SERVICES</h2>
                            <p className='title-p'>We think like a customer and perform with ownership because we belive we're only successful if our clients are</p>
                        </div>
                    </div>
                </div>

                <div className='backgroundHome'>

                    <section id="sliderHome" className="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-touch="true">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className='container'>
                                    <div className="row ">
                                        <div className="col-md-4 pt-2 card">
                                            <div className=" border-0 p-4">
                                                <div className='card-img'>
                                                    <img src="img/serviceIcon1.png" alt="" className='icon' />
                                                </div>
                                                <div className="card-body text-center">
                                                    <h4 className="title-p">SOFTWARE ENGINEERING</h4>
                                                    <p className="title-p"> Cyshield provides practical
                                                        exercises to quickly document
                                                        and outline the business
                                                        loopholes and missing
                                                        functionalities and to detect
                                                        and respond to various
                                                        prepared attack scenarios.</p>
                                                </div>
                                                <div className="card-footer ">
                                                    <button className='softwareButton' onClick={navigateToSoftware}>View more</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 pt-2 card">
                                            <div className=" border-0 p-4">
                                                <div className='card-img'>
                                                    <img src="img/serviceIcon2.png" alt="" className='icon' />
                                                </div>
                                                <div className="card-body text-center">
                                                    <h4 className="title-p">AI & DATA SCIENCE</h4>
                                                    <p className="title-p"> The artificial intelligence and data science team employs cutting-edge artificial
                                                        intelligence, machine learning, and big data technologies to offer data-driven
                                                        decision-making for your customers.</p>
                                                </div>
                                                <div className="card-footer ">
                                                    <button onClick={navigateToAIDS}>View more</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 pt-2 card">
                                            <div className=" border-0 p-4">
                                                <div className='card-img'>
                                                    <img src="img/serviceIcon3.png" alt="" />
                                                </div>
                                                <div className="card-body text-center">
                                                    <h4 className="title-p">INFRASTRCTURE SECURITY</h4>
                                                    <p className="title-p"> At Cyshield, we employ subject matter experts who cover a vast range of technologies including but not limited to the market norms (Firewalls, IPS/IDS, SIEM, Proxy, VPNs (SSL/ IPSEC/others).</p>
                                                </div>
                                                <div className="card-footer ">
                                                    <button onClick={navigateToSOC}>View more</button>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="carousel-item ">
                                <div className='container'>
                                    <div className="row ">
                                        <div className="col-md-4 pt-2 card">
                                            <div className=" border-0 p-4">
                                                <div className='card-img'>
                                                    <img src="img/serviceIcon4.png" alt="" className='icon' />
                                                </div>
                                                <div className="card-body text-center">
                                                    <h4 className="title-p">CYBER SECURITY</h4>
                                                    <p className="title-p">  Cyshield offers secure code review services to review a given scope of an applications source code to identify security related weaknesses in the code. A secure code review provides security insights.</p>
                                                </div>
                                                <div className="card-footer ">
                                                    <button className='softwareButton' onClick={navigateToCyberSecurity}>View more</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 pt-2 card">
                                            <div className=" border-0 p-4">
                                                <div className='card-img'>
                                                    <img src="img/serviceIcon5.png" alt="" className='icon' />
                                                </div>
                                                <div className="card-body text-center">
                                                    <h4 className="title-p">RED TEAM SERVICES</h4>
                                                    <p className="title-p">  Cyshield provides exercises that aim to simulate a
                                                        real attack with no disruption of services.
                                                        We are always up to date on malicious attackers
                                                        TTPs (Tactics, Techniques and Procedures) as well
                                                        as the latest trends.</p>
                                                </div>
                                                <div className="card-footer pt-4 ">
                                                    <button onClick={navigateToRTS}>View more</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 pt-2 card">
                                            <div className=" border-0 p-4">
                                                <div className='card-img'>
                                                    <img src="img/serviceIcon6.png" alt="" />
                                                </div>
                                                <div className="card-body text-center">
                                                    <h4 className="title-p ">GOVERNANCE, RISK & COMPLIANCE</h4>
                                                    <p className="title-p"> Governance, Risk & Compliance (GRC) is about identifying and controlling
                                                        risks, complying with new rules and regulations, maintaining right to do
                                                        business.</p>
                                                </div>
                                                <div className="card-footer pt-4">
                                                    <button onClick={navigateToGRC}>View more</button>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                        <button type="button" className="carousel-control-prev" data-bs-target="#sliderHome" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon " ></span>
                        </button>
                        <button type="button" className="carousel-control-next" data-bs-target="#sliderHome" data-bs-slide="next">
                            <span className="carousel-control-next-icon "></span>
                        </button>
                    </section>


                </div>

            </section>

            {/* WHO WE ARE ? part*/}
            <section id="HomeAbout" className="pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 align-self-center about-img">
                            <img src="img/Home2.png" alt="" />
                        </div>
                        <div className="col-md-6 align-self-center about-text">
                            <div className='redLine'></div>
                            <span >WHO WE ARE ?</span>

                            <p className="mb-4 title-p" > Cyshield employs world-class Digital professionals with cutting edge
                                knowledge and experience .Our wide experience in the digital space
                                makes us the best choice for your company needs and our focus on
                                highly specialized areas allows us to deliver superior consultation
                                services that are the best in the market. We are dedicated to work
                                hard in order to escort our clients to their ultimate goals.</p>
                            <button onClick={navigateToAbout}> Know more about us</button>
                        </div>

                    </div>
                </div>
            </section>




            {/*NewsLetter*/}
            <section id="newsletter" className="py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8">
                            <div className='whiteLine'></div>
                            <h6 >Let's Talk</h6>
                            <h4>About your next project </h4>
                            <p className='title-p'></p>
                            <button type="button" className="btn" onClick={navigateToCntactus}>Get In Touch</button>
                        </div>

                    </div>
                </div>
            </section>


            {/*Footer*/}
            <Footer />

        </body>
    );
}