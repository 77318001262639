
import * as React from 'react';

export default function Navbar(props) {
    return (
        <body data-bs-spy="scroll" data-bs-target="#main_menu">
            <div id="home"></div>
            <nav id="main_menu" className="navbar navbar-expand-md navbar-light  ">
                <div className="container">
                    <a className="navbar-brand" href="home"><img src="img/logo.png" alt="" /></a>
                    <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#mainnav">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div id="mainnav" className="collapse navbar-collapse ">
                        <ul className="navbar-nav ms-auto ">
                            <li><a className="nav-link" href="home">Home</a></li>
                            <li ><a className="nav-link  " href='#childs'>Services</a>
                                <ul id='childs'>
                                    <li><a className="nav-link" href="AIDS">AI & DS</a></li>
                                    <li><a className="nav-link" href="grc">GRC Services</a></li>
                                    <li><a className="nav-link" href="rts">RTS Services</a></li>
                                    <li><a className="nav-link" href="cyberSecurity">Cyber Security</a></li>
                                    <li><a className="nav-link" href="software">Software Engineering</a></li>
                                    <li><a className="nav-link" href="soc">Secure Infrastructure</a></li>
                                </ul>
                            </li>

                            <li><a className="nav-link" href="https://careers.cyshield.com/" target="_blank">Careers</a></li>
                            <li><a className="nav-link" href="blog">Blog</a></li>
                            <li><a className="nav-link" href="aboutUs">About Us</a></li>
                            <li><a className="nav-link contact" href="contactUs">Contact Us</a></li>
                        </ul>
                    </div>
                </div>


            </nav>
            <div className='row'>
                <img src='./img/gradientLine.png' className='image' />
            </div>





        </body>
    );
}