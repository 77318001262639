import * as React from 'react';
import Nav from '../sharedComponents/navbar'
import Footer from '../sharedComponents/footer'
import BlueCard from '../sharedComponents/blueCard'
export default function Software(props) {


    return (
        <body data-bs-spy="scroll" data-bs-target="#main_menu">
            <div id="home"></div>
            <Nav />

            {/* First Part*/}
            <section id="about" className="pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 align-self-center about-text">
                            <div className='redLine'></div>
                            <span className="title-md" >DISCOVER OUR SERVICES</span>
                            <h6 className="title-sm">Software Engineering & Development</h6>

                        </div>

                        <div className="col-md-6 align-self-center about-img">
                            <img src="img/Software.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            {/* Software Engineering & Development Part*/}
            <section id="AIFullImage" className="pb-5">



                <div className='backgroundSoftware'>

                    <div className="container">
                        <div className="row mb-3 pt-2">
                            <div className="col-md-12  text-left mb-3">
                                <h2 className="title-md">Software Engineering & Development</h2>
                                <p className='title-p '>Cyshield provides practical exercises to quickly document and outline the business
loopholes and missing functionalities<br/> and to detect and respond to various prepared attack scenarios.</p>

                            </div>
                        </div>
                    </div>

                    <div className='container'>
                        <div className="row ">
                            <div className="col-md-6 pt-2 text-left ">
                                <h3 className="title-md">ERP/CRM/LMS:</h3>
                                <img class="Arrow" src="img/Arrow.png" />
                                <div className='box'>
                                    <p className='title-p '>
                                        Cyshield offers a variety of Enterprise Resource Planning (ERP),
                                        customer relationship management (CRM), and learning management systems (LMS) that are designed to run your entire business.</p>
                                </div>

                                <h3 className="title-md">Microservices development:</h3>
                                <img class="Arrow" src="img/Arrow.png" />
                                <div className='box'>
                                    <p className='title-p '>
                                    Instead of a traditional, monolithic approach to apps where everything is built into a single whole, microservices are all separate and work together to accomplish the same tasks. Through microservices, Cyshield provides granularity, lightweightness, and the ability to share similar process across multiple apps.</p>
                                </div>


                                <h3 className="title-md">Mobile applications:</h3>
                                <img class="Arrow" src="img/Arrow.png" />
                                <div className='box'>
                                    <p className='title-p '>
                                    Cyshield builds robust, secure, and user-friendly mobile applications that will put your products at the fingertips of everyone.</p>
                                </div>




                            </div>
                            <div className="col-md-6 pt-2 text-left ">
                                <h3 className="title-md">Web services and applications:</h3>
                                <img class="Arrow" src="img/Arrow.png" />
                                <div className='box'>
                                    <p className='title-p '>
                                    Cyshield develops web services and applications that deliver exceptional customer experience. We publish information about the services your organization provides in a Web Service registry that can be queried by members of the organization or possibly by other businesses.</p>
                                </div>

                                <h3 className="title-md">Omni-channel communication:</h3>
                                <img class="Arrow" src="img/Arrow.png" />
                                <div className='box'>
                                    <p className='title-p '>
                                    Cyshield offers a wide range of omni-channel communication services
                                        to support your enterprise reach customers in the most optimal way. Our services include segmented messaging services over SMS, WhatsApp, Messenger, and Twitter, as well as social media accounts management, automated post handling, and Payment integrator with Point of Sale.</p>
                                </div>

                            </div>

                        </div>


                    </div>





                </div>

            </section>



            {/*  Platform Services Part*/}
            <section id="GRC" >

                <div className="container">
                    <div className="row mb-3">
                        <div className="col-md-12  text-left mb-2">
                            <h2 className="title-md"> Platform Services</h2>

                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-md-12  ">
                            <h3 className="title-md" >Cloud-based applications:</h3>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                We provide Platform as a Service (PaaS) through open-source development platforms such as OpenShift, which enables developers to develop and deploy their applications on cloud infrastructure. Our PaaS greatly simplifies developing cloud-enabled services.</p>
                            </div>

                            <h3 className="title-md" >Database systems:</h3>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                Cyshield provides secure, robust, and efficient database systems for all types of data. Your data will be stored securely in our data centers and managed by our ultra-secure database management system.</p>
                            </div>

                            <h3 className="title-md" >IOT platform and applications:</h3>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                Cyshield offers multi-layer technology that enables straightforward provisioning, management, and automation of connected devices within the Internet of Things universe via secure and robust end-to-end connectivity.</p>
                            </div>

                        </div>

    
                    </div>
                </div>
            </section>


            {/*NewsLetter*/}
            <BlueCard />


            {/*Footer*/}
            <Footer />

        </body>
    );
}