import axios from 'axios';
import config from '../config/config.json'
import {token} from './token';

const url =`${config.backend.baseurl+'/api/blog-articles?populate=*'}`
export const readArticles= () => axios.get(url,{headers: { 
    'Authorization': token}});


const Technology_url =`${config.backend.baseurl+'/api/blog-technologies?populate=*'}`
export const readTechnology= () => axios.get(Technology_url,{headers: { 
    'Authorization': token}});


const Branding_url =`${config.backend.baseurl+'/api/blog-brandings?populate=*'}`
export const readBranding= () => axios.get(Branding_url,{headers: { 
    'Authorization': token}});


const Teamwork_url =`${config.backend.baseurl+'/api/blog-team-works?populate=*'}`
export const readTeamwork= () => axios.get(Teamwork_url,{headers: { 
    'Authorization': token}});

// import axios from 'axios';
// import config from '../config/config'
// const url =`${config.backend.baseurl+'/api/blog-articles?populate=*'}`
// export const readArticles= () => axios.get(url);


// const Technology_url =`${config.backend.baseurl+'/api/blog-technologies?populate=*'}`
// export const readTechnology= () => axios.get(Technology_url);


// const Branding_url =`${config.backend.baseurl+'/api/blog-brandings?populate=*'}`
// export const readBranding= () => axios.get(Branding_url);


// const Teamwork_url =`${config.backend.baseurl+'/api/blog-team-works?populate=*'}`
// export const readTeamwork= () => axios.get(Teamwork_url);
