import * as React from 'react';
import Nav from '../sharedComponents/navbar'
import Footer from '../sharedComponents/footer'
import { useNavigate } from 'react-router-dom';
import * as api from '../Apis/contactUs';
export default function Contactus(props) {
    const [contactus, setContactus] = React.useState({});
    const navigate = useNavigate();

    const createMesaage = () => {
        let newObj = {
            data: contactus
        }

        if (Object.keys(newObj.data).length === 0) {
            newObj.data={}
        } else {
            api.createMessage(newObj)
            newObj.data={}
        }
       
       // window.location.reload(true)
    }


    const navigateToCyberSecurity = () => {
        navigate('/cyberSecurity');
    };

    return (
        <body data-bs-spy="scroll" data-bs-target="#main_menu">
            <div id="home"></div>
            <Nav />

            {/* First Part*/}

            <section id="about" className="pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 align-self-center about-text">
                            <div className='redLine'></div>
                            <span className="title-md" >CONTACT US</span>
                            <h6 className="title-sm">Do you want to work together,
                                or talk about an idea ? Let's do this</h6>
                            <p className="mb-4 title-p" >Get creative solutions, Everyone is a potential creator,<span className='follow'>Follow</span> us on :</p>

                            <div className='row'>
                                <div className=' align-self-center liststyle'>
                                    <ul className="list-unstyled ">
                                        <li className="mb-2"><a href="https://www.facebook.com/CyShield" target="_blank"> <img className="facebook" src="img/Facebook.png" /></a> </li>
                                        <li className="mb-2"><a href="https://www.linkedin.com/company/cyshield/" target="_blank"><img className='Linkedin' src="img/Linkedin.png" /></a> </li>
                                        <li className="mb-2"><a href="https://twitter.com/cyshieldcompany?s=11&t=WrxLhcE1R_KN1Ur3hDH49w" target="_blank"><img className='Twitter' src="img/Twitter.png" /></a></li>
                                        <li className="mb-2"><a href="https://instagram.com/cy_shield?igshid=YmMyMTA2M2Y=" target="_blank"><img className='Instgram' src="img/Instgram.png" /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 align-self-center about-img">
                            <img src="img/Home1.png" alt="" />
                        </div>
                    </div>

                </div>
            </section>


            {/* Send message Part*/}
            <section id="sendMessagge" className="pb-5">
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-md-8 offset-md-2 text-center">
                            <h2 className="title-md">SEND US A MESSAGE</h2>
                            <p className='title-p'>To request more information, Please fill the form</p>
                        </div>
                    </div>
                </div>

                <div className='container   '>
                    <div className="row">
                        <div className="col-md-6 pt-2 ">
                            < input type="text" placeholder="Full Name" value={contactus.Full_name} onChange={e => setContactus({ ...contactus, Full_name: e.target.value })} required/>
                        </div>
                        <div className="col-md-6 pt-2 ">
                            < input type="text" placeholder="Job Title" value={contactus.Job_title} onChange={e => setContactus({ ...contactus, Job_title: e.target.value })} required/>
                        </div>

                    </div>
                    <div className="row ">
                        <div className="col-md-6 pt-2 ">
                            < input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"  title="Invalid email address"  placeholder="Email Address" value={contactus.Email_Address} onChange={e => setContactus({ ...contactus, Email_Address: e.target.value })} required/>
                        </div>
                        <div className="col-md-6 pt-2 ">
                            < input type="number" placeholder="Phone Number" value={contactus.Phone_number} onChange={e => setContactus({ ...contactus, Phone_number: e.target.value })} required/>
                        </div>

                    </div>

                    <div className="row ">
                        <div className="col-md-12 pt-2 ">
                            < input type="text" placeholder="Your Message" className='message' value={contactus.Message} onChange={e => setContactus({ ...contactus, Message: e.target.value })} required/>
                        </div>
                        <button type="submit" onClick={createMesaage}>Send message</button>


                    </div>
                </div>

            </section>

            {/* Contact Information part*/}
            <section className="pb-5">

                <div className='row'>
                    <img src='./img/contactus.png' />
                </div>

            </section>

            {/*  Information part*/}
            <section className="pb-5">
                <div className='container'>
                    <div className='row'>
                        <img src='./img/contactus2.png' />
                    </div>
                </div>
            </section>




            {/*NewsLetter*/}
            <section id="newsletter" className="py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8">
                            <div className='whiteLine'></div>
                            <h6 >Discover us</h6>
                            <h4>Our creative works</h4>
                            <p className='title-p'></p>
                            <button type="button" className="btn" onClick={navigateToCyberSecurity}>Discover</button>
                        </div>

                    </div>
                </div>
            </section>


            {/*Footer*/}
            <Footer />

        </body>
    );
}