import * as React from 'react';
import Nav from '../sharedComponents/navbar'
import Footer from '../sharedComponents/footer'
import BlueCard from '../sharedComponents/blueCard'
export default function GRC(props) {


    return (
        <body data-bs-spy="scroll" data-bs-target="#main_menu">
            <div id="home"></div>
            <Nav />

            {/* First Part*/}
            <section id="about" className="pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 align-self-center about-text">
                            <div className='redLine'></div>
                            <span className="title-md" >DISCOVER OUR SERVICES</span>
                            <h6 className="title-sm">Governance, Risk & Compliance (GRC)</h6>

                        </div>

                        <div className="col-md-6 align-self-center about-img">
                            <img src="img/GRC.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            {/* GRC Services Part*/}
            <section id="AIFullImage" className="pb-5">


                <div className='backgroundGRC'>
                    <div className='container'>
                        <div className="row ">
                            <div className="col-md-6 pt-2 text-left ">
                                <h2 className="title-md">  GRC Services</h2>
                                <img class="Arrow" src="img/Arrow.png" />
                                <div className='box'>
                                    <p className='title-p '>
                                        Cyshield is a pioneer in the field of Governance, Risk and Compliance.
                                        With an established Center of Excellence for Governance, Risk &
                                        Compliance consulting, our seasoned and experienced practitioners
                                        provide strategic insights and advisory services to tackle risk and
                                        compliance challenges across the enterprise.</p>
                                </div>

                                <img class="Arrow" src="img/Arrow.png" />
                                <div className='box'>
                                    <p className='title-p '>
                                        Cyshield Governance Risk & Compliance services are designed to help
                                        your organization better identify, understand and manage the dynamic
                                        interrelationships between risk and compliance and incorporate those
                                        disciplines into daily business activities.</p>
                                </div>

                                <img class="Arrow" src="img/Arrow.png" />
                                <div className='box'>
                                    <p className='title-p '>
                                        Cyshield has developed industry specific GRC solutions portfolio
                                        (combinations of advisory services, methodologies, and tools) that
                                        address both the regulatory and strategic needs of an enterprise.
                                        Engagements with organizations across industry verticals stand as
                                        testimony to our expertise in this domain. Our services go beyond
                                        regulatory compliance and incorporate international best practices,
                                        helping your organization unlock its true potential.</p>
                                </div>

                                <img class="Arrow" src="img/Arrow.png" />
                                <div className='box'>
                                    <p className='title-p '>
                                        Cyshield solution portfolio includes Business domain and Technology
                                        expertise, a unique combination which drives a successful GRC program
                                        in an enterprise. Our experience in various industry verticals coupled with
                                        deep domain knowledge has created unmatched solution capabilities
                                        that help our clients reduce their risk exposure and improve governance
                                        while proactively managing compliance, all with a GRC system.</p>
                                </div>


                            </div>
                            <div className="col-md-6 pt-2 ">
                            </div>

                        </div>


                    </div>





                </div>

            </section>





            {/* Our Governance Risk Part*/}
            <section id="GRC" >

                <div className="container">
                    <div className="row mb-3">
                        <div className="col-md-12  text-left mb-5">
                            <h2 className="title-md">Our Governance Risk & Compliance portfolio comprises the following:</h2>

                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-md-6  ">
                            <h3 className="title-md" >Compliance Management Services</h3>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                    Setup, Enhance and manage an effective regulatory and security standard (PCI-DSS, ISO/IEC 27000, NIST and HIPAA) compliance management system to manage multiple requirements within
                                    an organization.</p>
                            </div>

                            <h3 className="title-md" >Data Privacy Services</h3>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                    Strengthen and build reliable services in a datasensitive environment with the help of Inventory and Data flow mapping, privacy program
                                    implementation, impact assessments, regulation analysis and incident management.</p>
                            </div>

                            <h3 className="title-md" >GRC Platform Implementation
                                and Support Services</h3>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                    Increase visibility with IT GRC solutions, implementation, integration and monitoring.</p>
                            </div>





                        </div>

                        <div className="col-md-6  ">
                            <h3 className="title-md" >Governance and Risk Management Services</h3>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                    Build and enhance IT governance, Enterprise & IT risk management processes with strategies & roadmaps, effective policy management,
                                    operations and regulatory risk management processes.</p>
                            </div>

                            <h3 className="title-md" > Audit and Assessment Services</h3>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                    Build, perform and manage audits and assessments related to IT Governance, Regulatory/Policy Compliance, Security/Information Security, Third Party Control & Governance and provide external and
                                    internal audit support.</p>
                            </div>






                        </div>


                    </div>
                </div>
            </section>


            <section id="GRC" className=" " >

                <div className="container">
                    <div className="row">

                        <div className="col-md-12  ">
                            <img src="img/GRCImage2.png" />
                        </div>


                    </div>
                </div>
            </section>


            {/*NewsLetter*/}
            <BlueCard />


            {/*Footer*/}
            <Footer />

        </body>
    );
}