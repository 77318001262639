import * as React from 'react';
import Nav from '../sharedComponents/navbar'
import Footer from '../sharedComponents/footer'
import BlueCard from '../sharedComponents/blueCard'
export default function RTS(props) {


    return (
        <body data-bs-spy="scroll" data-bs-target="#main_menu">
            <div id="home"></div>
            <Nav />

            {/* First Part*/}
            <section id="about" className="pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 align-self-center about-text">
                            <div className='redLine'></div>
                            <span className="title-md" >DISCOVER OUR SERVICES</span>
                            <h6 className="title-sm">RTS Services</h6>

                        </div>

                        <div className="col-md-6 align-self-center about-img">
                            <img src="img/RTS.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>


            {/* Tablate Part*/}
            <section id="computerVision" className="pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6  ">
                            <h2 className="title-md" > Tabletop Exercise</h2>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                    Cyshield provides practical exercises to quickly document and outline the business loopholes and missing functionalities and to detect and respond to various prepared attack scenarios.</p>
                            </div>

                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                    After aligning with the client and the business process, the exercise begins by building the business use cases and agreeing on different simulation environments. Our team then meets with the client’s team to propose different attack scenarios and discuss what would happen in case of such attacks. We navigate through the implemented controls and discuss the impact and residual risk.
                                    <br></br>
                                    <br></br>
                                    In the end, outcomes are evaluated based on regulations and the company`s implemented procedures. Tabletop exercises help to better understanding the threat landscape and the readiness of the people, technology support and process effectiveness. Usually a red teaming exercise follows to accurately measure the risks and impact on the customer.</p>
                            </div>

                            <h2 className="title-md" >Adversary Emulation Exercise</h2>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                    This Exercise is intended to provide your company with the experience of a complex coordinated attack by real-world threat actors without the disruption or costs associated with an actual breach. The tests are conducted in an automated manner through scenarios with custom configuration as per the customer environment. It provides more in-depth, verified results rather than the usual vulnerability assessments.</p>
                            </div>



                        </div>

                        <div className="col-md-6  about-img">
                            <img src="img/RTS2.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            {/* Cloud Infrastructure Assessment Part*/}
            <section id="GRC" >
                <div className="container">
                    <div className="row">
                        <div className="col-md-6  ">
                            <h2 className="title-md" >Cloud Infrastructure Assessment</h2>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                    Cyshield provides Amazon, Azure and Google Cloud services security assessments. This service examines the full deployment of cloud services in terms of connectivity, security roles of the users and firewall rules in addition to alerts and exposed services from the instances. The report will indicate all the findings with the suggested remediation steps specific to the platform.</p>
                            </div>

                        </div>

                        <div className="col-md-6  ">
                            <h2 className="title-md" >Red Team Exercise</h2>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                    Cyshield provides exercises that aim to simulate a real attack with no disruption of services. We are always up to date on malicious attackers TTPs (Tactics, Techniques and Procedures) as well as the latest trends, tools and techniques used by the information security community. Our extensive experience accumulated through regular penetration tests and previous engagements is channeled through simulated attacks to put your cyber security to the ultimate test.</p>
                            </div>
                        </div>


                    </div>
                </div>
            </section>


            {/* Phishing Simulator Part*/}
            <section id="NaturalLanguage" className="pb-5 " >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12  ">
                            <h2 className="title-md" > Phishing Simulator</h2>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                    Phishing is the art of sending spoofed emails/ SMS that look legitimate and appear to be from trusted senders. Phishing’s goal is to get and maintain access to the infrastructure by attacking the human element in the technology lifecycle. Cyshield’s provides custom portals with various adjusting templates in multiple languages to serve attack payloads based on the requested scenario. These portals are essentially platforms to ease the continuous process of assessing and enhancing the security awareness of the employees in a measurable manner. Some security companies look at employees as a security burden, Cyshield sees them as a security layer if properly trained.</p>
                            </div>

                            <h2 className="title-md" >  Purple Team Exercise</h2>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                    Cyshield’s purple team exercises are the Cyber Drills perfect simulation of your defenses and controls against realistic attacks. After agreeing on the scope and attack vectors our Red Team starts the attacks and aligns with the customer’s Blue Team in order to effectively measure which attacks breached the defenses, and which of them didn’t trigger any logs.
                                    <br></br>
                                    <br></br>
                                    After the attack scenarios, several meetings with the blue team are held to properly optimize and finetune the controls in order to be able to detect the similar attacks. The exercise is considered the most practical collaborative approach to strengthening your defenses and a means to enhance your own Blue Team`s expertise.</p>
                            </div>

                            <h2 className="title-md" >  Cyber Drills</h2>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                Cyshield’s cyber drills are the most comprehensive service for large-scale organizations. The drills involve the combined experience of our various departments (Integration Services, SOC, Artificial Intelligence & Machine Learning, Software engineering, GRC, Red Teaming) in a joint effort in order to architect high level attack scenarios on a country- or ministry-wide scale.
The drills aim to highlight possible breach points and simulate harmless attacks in order to detect and highlight the possible impact of the security exposure of critical infrastructure and to further protect the digital identity of the target country. The drills will mostly require integration with the country`s CERT/CIRT teams in collaboration with the ministry`s representatives.</p>
                            </div>



                            


                        </div>

                    </div>
                </div>
            </section>


            {/* Assumed Breach Assessment Part*/}
            <section id="computerVision" className="pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6  ">
                            <h2 className="title-md" > Assumed Breach Assessment</h2>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                    When you need to assess the effectiveness of your internal segregation and security controls, Cyshield’s assume breach assessment is the most adequate service. The assessment requires planting a malicious undetected payload, whether an office file, a web shell on the internal web server, or a simple exe file. The payload can be run by a regular employee or another high-level official in the customer’s company, and the aim is to see how far we can go through the internal networks and gather important information before getting caught. Several scenarios can be tested, ranging from ransomware detection simulations to full network compromise.</p>
                            </div>


                            <h2 className="title-md" >Active & Office365 Directory Security
                                Assessment</h2>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                    Cyshield offers a specialized dedicated assessment for Azure-hosted Active Directory or on-premises AD and Office365  deployments in order to fine-tune configurations to be resilient and capable of detecting suspicious activity. Deployments can be further supplanted with specialized software to react based on predefined policies as part of security operations and response (SOAR). Configuration reviews are included in order to make sure that the logs and scoping are enough to detect most of the attacks and aid the IR team in understanding the full picture.</p>
                            </div>



                        </div>

                        <div className="col-md-6 align-self-center about-img">
                            <img src="img/RTS3.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            {/*NewsLetter*/}
            <BlueCard />


            {/*Footer*/}
            <Footer />

        </body>
    );
}