import * as React from 'react';
import Nav from '../sharedComponents/navbar'
import Footer from '../sharedComponents/footer'
import BlueCard from '../sharedComponents/blueCard'
export default function AIDS(props) {


    return (
        <body data-bs-spy="scroll" data-bs-target="#main_menu">
            <div id="home"></div>
            <Nav />

            {/* First Part*/}
            <section id="about" className="pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 align-self-center about-text">
                            <div className='redLine'></div>
                            <span className="title-md" >DISCOVER OUR SERVICES</span>
                            <h6 className="title-sm">Artificial Intelligence & Data Science</h6>

                        </div>

                        <div className="col-md-6 align-self-center about-img">
                            <img src="img/AIDS.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            {/* Big Data Part*/}
            <section id="AIFullImage" className="pb-5">
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-md-12  text-left mb-5">
                            <h2 className="title-md">Artificial Intelligence & Data Science</h2>
                            <p className='title-p'> The artificial intelligence and data science team employs cutting-edge artificial intelligence, machine learning, and big data technologies to offer data-driven decision-making for your customers.</p>
                        </div>
                    </div>
                </div>

                <div className='backgroundHome'>
                    <div className='container'>
                        <div className="row ">
                            <div className="col-md-6 pt-2 text-left ">
                                <h2 className="title-md">  Statistical Modelling and Analysis</h2>
                                <img class="Arrow" src="img/Arrow.png" />
                                <div className='box'>
                                    <p className='title-p '>
                                        At Cyshield, AI is not just a buzzword for us! We know exactly how to realize the full potential of AI to support your business. Our data scientists will quickly build and fine-tune predictive analytics solutions to provide you with actionable insights to take your business to the next level.
                                        <br />
                                        <br />
                                        We will gladly take care of all the complicated infrastructure required to build and maintain AI models so that you can focus on what matters.</p>
                                </div>
                            </div>
                            <div className="col-md-6 pt-2 ">
                            </div>

                        </div>

                        <div className="row ">
                            <div className="col-md-6 pt-2 ">
                            </div>
                            <div className="col-md-6 pt-2 text-left ">
                                <h2 className="title-md">  Big Data</h2>
                                <img class="Arrow" src="img/Arrow.png" />
                                <div className='box'>
                                    <p className='title-p '>
                                        With an ever-increasing amount of data being created every day, specialized big data platforms, pipelines and analysis technique are required to handle storage and analysis of such immense volumes of data.
                                        <br></br>
                                        <br></br>
                                        Cyshield big data services to efficiently acquire, transform, and analyze data. Our data scientists and engineers will help you make sense out of big data and provide you with valuable insights that can transform your business.</p>
                                </div>
                            </div>


                        </div>
                    </div>





                </div>

            </section>

            {/* Computer Vision Part*/}
            <section id="computerVision" className="pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7  ">
                            <h2 className="title-md" >Computer Vision</h2>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                    With the prevalence of cameras and CCTV, computer vision techniques have become indispensable to extract information from visual data. At Cyshield, we build state-of-the-art deep learning models to detect and identify any relevant information from images and videos.</p>
                            </div>

                            <h3 className="title-md" >Our computer vision applications include:</h3>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <h3 className='title-p '>
                                    Object detection:</h3>
                                <p className='title-p '> detect and identify objects of
                                    interest in images and videos.</p>
                            </div>

                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <h3 className='title-p '>
                                    Facial Recognition:</h3>
                                <p className='title-p '>Recognize people and facial
                                    features in images and videos.</p>
                            </div>


                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <h3 className='title-p '>
                                    Activity recognition:</h3>
                                <p className='title-p '>Recognize certain activities
                                    from video feeds.</p>
                            </div>
                        </div>

                        <div className="col-md-5 align-self-center about-img">
                            <img src="img/ComputerVision.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>



            {/* Computer Vision Part*/}
            <section id="NaturalLanguage" className="pb-5 " >
                <div className="container">
                    <div className="row">
                        <div className="col-md-6  ">
                            <h2 className="title-md" >  Natural Language Processing</h2>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                    Natural language processing (NLP) is one of the hottest topics in
                                    AI right now, and rightly so since it allows AI-enabled products to
                                    understand people and interact with them.</p>
                            </div>

                            <h2 className="title-md mb-5" >Cyshield offers state-of-the-art NLP solutions in
                                various languages for the following applications:</h2>

                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <h3 className='title-p '>
                                Chatbots:</h3>
                                <p className='title-p '>Deploy automated chatbot that can understand your users and respond to them in a natural way.</p>
                            </div>

                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <h3 className='title-p '>
                                Machine translation:</h3>
                                <p className='title-p '>Translate from any language to any other language on the fly.</p>
                            </div>

                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <h3 className='title-p '>
                                Sentiment Analysis:</h3>
                                <p className='title-p '>Understand how your users feel so that you can cater to their taste better.</p>
                            </div>


                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <h3 className='title-p '>
                                Text summarization and keywork extraction:</h3>
                                <p className='title-p '>Understand the gist of what your users are saying immediately without having to read through everything yourself.</p>
                            </div>
                        </div>

                        <div className="col-md-6  ">
                            <h2 className="title-md" >OCR and Document Digitization</h2>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                Digitization of document archives is an important step in the
                                        digital transformation of modern organizations.
                                        Cyshield offers optical character recognition (OCR) solutions for various languages to transform your documents into digital format.</p>
                            </div>

                            <h2 className="title-md mb-5" >Our OCR solutions can handle:</h2>

                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <h3 className='title-p '>
                                Noisy scans:</h3>
                                <p className='title-p '> With integrated image preprocessing, our system can clean up noisy and old documents to get accurate results.</p>
                            </div>

                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <h3 className='title-p '>
                                Complex fonts and scripts:</h3>
                                <p className='title-p '>Even if documents are written in a complex font, our system will be finetuned to get the best results for your documents.</p>
                            </div>

                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <h3 className='title-p '>
                                Sentiment Analysis:</h3>
                                <p className='title-p '>Understand how your users feel so that you can cater to their taste better.</p>
                            </div>


                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <h3 className='title-p '>
                                Complex layouts:</h3>
                                <p className='title-p '>No matter how your documents are laid out, our system will read every line and preserve the layout.</p>
                            </div>

                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <h3 className='title-p '>
                               Multiple Languages:</h3>
                                <p className='title-p '>Our system will read documents in almost any language, even bilingual documents.</p>
                            </div>

                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <h3 className='title-p '>
                                Ambiguous text:</h3>
                                <p className='title-p '>Our system will resolve ambiguous text and typos in your documents with an integrated text postprocessing system.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


           {/*NewsLetter*/}
           <BlueCard />


            {/*Footer*/}
            <Footer />

        </body>
    );
}