import * as React from 'react';
import Nav from '../sharedComponents/navbar'
import Footer from '../sharedComponents/footer'
import * as api from '../Apis/carrers';
import config from '../config/config.json'
export default function Career(props) {

    const [career, setCareer] = React.useState([]);
    const [keyword, setKeyword] = React.useState('');
    const [job_type, setJob_type] = React.useState('');
    const [age, setAge] = React.useState('');
    React.useEffect(() => {
        fetchData(api.readCareers())
    }, [])

    function convertDigitIn(str) {
        return str.split('-').reverse().join('-');
    }

    var fetchData = async (result) => {
        result = await result
        setCareer(result.data.data)
    }

    //Handle change of keyword
    const handleChangeKeywork = (event) => {
        setKeyword(event.target.value);
    };
    //Handle change of job type
    const handleChange_job = (event) => {
        setJob_type(event.target.value);
    };
    //Handle chage of department
    const handleChange = (event) => {
        setAge(event.target.value);
        console.log(event.target.value)
    };
    const handleClick = event => {
        console.log(event.currentTarget.id);
        if (event.currentTarget.id === 'Filter') {
           // console.log(keyword)
            fetchData(api.readCareerss({ Job_type: job_type, Department: age, keyword: keyword }))

        }
    }


    return (
        <body data-bs-spy="scroll" data-bs-target="#main_menu">
            <div id="home"></div>
            <Nav />

            {/* First Part*/}

            <section id="about" className="pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 align-self-center about-text">
                            <div className='redLine'></div>
                            <span className="title-md" >JOIN OUR TEAN</span>
                            <h6 className="title-sm">If you are looking to grow your career! "Here We Are"</h6>
                            <p className="mb-4 title-p" >A fast growing cutting edge technology enterprise that offers abroad support to our family
                                members while they experience our unique culture and lifestyle.</p>


                        </div>
                        <div className="col-md-6 align-self-center about-img">
                            <img src="img/Home1.png" alt="" />
                        </div>
                    </div>

                </div>
            </section>


            {/* Open Vacancy*/}
            <section id="Vacancy" className="pb-5">
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-md-8  ">
                            <h2 className="title-md">OUR OPEN VACANCIES</h2>
                            <p className='title-p'>Thanks for checking out our job openings. See something that interests you?</p>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className="row ">
                        <div className="col-md-2 pt-2 ">
                            <h1>Search</h1>
                        </div>
                        <div className="col-md-2 pt-2 ">
                            < input type="text" placeholder="Keyword" onChange={handleChangeKeywork} />
                        </div>

                        <div className="col-md-3  ">
                            <select label="Job type"
                                onChange={handleChange_job}
                                value={job_type}
                            >
                                <option value={'Job Type'} className='input-text-job-subtitle'>Job Type </option>
                                <option value={'Full Time'} className='input-text-job-subtitle'>Full Time</option>
                                <option value={'Part Time'} className='input-text-job-subtitle'>Part Time</option>
                                <option value={'Internship'} className='input-text-job-subtitle'>Internship</option>
                            </select>

                        </div>

                        <div className="col-md-3  ">
                            <select
                                label="Age"
                                className='searcr_border2'
                                value={age}
                                onChange={handleChange}
                            >
                                <option value={'Departments'} className='input-text-job-subtitle'>Departments</option>
                                <option value={'AI-DS'} className='input-text-job-subtitle'>AI-DS</option>
                                <option value={'Cyber Security'} className='input-text-job-subtitle'>Cyber Security</option>
                                <option value={'Secure Infrastrcure'} className='input-text-job-subtitle'>Secure Infrastrcure</option>
                                <option value={'Red Team Services'} className='input-text-job-subtitle'>Red Team Services</option>
                                <option value={'Software Enginneering'} className='input-text-job-subtitle'>Software Enginneering</option>

                                <option value={'GOVERNANCE, RISK & COMPLIANCE'} className='input-text-job-subtitle'> Governance,Risk & Compliance</option>

                            </select>

                        </div>

                        <div className='col-md-2  pt-2 '>
                            <button id='Filter' onClick={handleClick} >Search</button>
                        </div>
                    </div>


                </div>


            </section>

            {/* Vacancies*/}
            <section id="vacancies" className="pb-5">


                <div className='container '>
                    <div className="row scroll">
                        {career.length !== 0 ? career.map((careers) => (
                            <div className="col-md-6 pt-2 card">
                                <h3>  {careers.attributes.Job_Title}</h3>
                                <h5>Department :<span> {careers.attributes.Department}</span> </h5>
                                <h5>Job Type : <span> {careers.attributes.Job_type}</span></h5>
                                <h5>Open Date : <span> {convertDigitIn(careers.attributes.Open_Date)}</span></h5>
                                <img src='./img/gradientLine.png' />
                            </div>
                        )) : <div className="container">
                            <div className="row mb-3 ">
                                <div className="col-md-8 offset-md-2 text-center">
                                    <h2 className="title-md">  No current vacancies at the moment</h2>
                                </div>
                            </div>
                        </div>}


                    </div>

                </div>

            </section>



            {/*NewsLetter*/}
            <section id="newsletter" className="py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8">
                            <div className='whiteLine'></div>
                            <h6 >JOIN OUR TEAM</h6>
                            <h4>No current opportunities meet your interest ?</h4>
                            <p className='title-p'> Just send us your CV</p>
                            <div class="input-group mb-3" >
                                <text type="text" class="form-control" className='checkButton' > career@cyshield.com</text>

                                <div class="input-group-append">
                                    <a href='mailto:career@cyshield.com'><button class="Discover_Button_check" type="button" >Open</button></a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            {/*Footer*/}
            <Footer />

        </body>
    );
}