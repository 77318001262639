import * as React from 'react';
import * as api from '../Apis/newsRoom';
import config from '../config/config.json'
import Nav from '../sharedComponents/navbar'
import Footer from '../sharedComponents/footer'
import { useNavigate } from 'react-router-dom';
export default function News(props) {

    const [article, setArticle] = React.useState([]);
    const [mostrecent, setMostrecent] = React.useState([]);
    const [mostRecentImage, setMostRecentImage] = React.useState([]);
    const [announcement, setAnnouncement] = React.useState([]);
    const [trending, setTrending] = React.useState([]);

    const [buttontraining, setButtontraining] = React.useState(false);
    const [buttonconferences, setButtonconferences] = React.useState(false);
    const [buttonevents, setButtonevents] = React.useState(false);
    const [buttonnewsletter, setButtonnewsletter] = React.useState(false);
    const [buttonsecurity, setButtonsecurity] = React.useState(false);
    
    React.useEffect(() => {
        var result = api.readTraining();
        fetchData(api.readTraining())

    }, [])

    const navigate = useNavigate();
    const navigateToAboutus = () => {
        // 👇️ navigate to /AboutUs
        navigate('/AboutUs');
    };

    var fetchData = async (result) => {
        result = await result
        for (let i = 0; i < result.data.data.length; i++) {

            if (result.data.data[i].attributes.card_type.data.attributes.Type === 'Announcement') {
                setAnnouncement(result.data.data[i].attributes)
            }

            else if (result.data.data[i].attributes.card_type.data.attributes.Type === 'Trending Now') {
                setTrending(result.data.data[i].attributes)
            }

            setMostrecent(result.data.data[i].attributes)
            setMostRecentImage(result.data.data[i].attributes.image.data[0].attributes.url)
        }
        setArticle(result.data.data)
    }


    const handleClick = event => {
        console.log(event.currentTarget.id);

        if (event.currentTarget.id === 'my-training') {
            setButtontraining(true)
            setButtonevents(false)
            setButtonconferences(false)
            setButtonnewsletter(false)
            setButtonsecurity(false)
            fetchData(api.readTraining())
        }
        else if (event.currentTarget.id === 'my-Conferences') {
            setButtonconferences(true)
            setButtontraining(false)
            setButtonnewsletter(false)
            setButtonsecurity(false)
            setButtonevents(false)
           fetchData(api.readConferences())
        }
        else if (event.currentTarget.id === 'my-Events') {
            setButtonevents(true)
            setButtontraining(false)
            setButtonconferences(false)
            setButtonnewsletter(false)
            setButtonsecurity(false)
            fetchData(api.readEvents())
        }
        else if (event.currentTarget.id === 'my-SecurityAvisor') {
            setButtonevents(false)
            setButtontraining(false)
            setButtonconferences(false)
            setButtonnewsletter(false)
            setButtonsecurity(true)
            fetchData(api.readSecurityAdvisor())
        }

        else if (event.currentTarget.id === 'my-NewsLetter') {
            setButtonevents(false)
            setButtontraining(false)
            setButtonconferences(false)
            setButtonnewsletter(true)
            setButtonsecurity(false)
            fetchData(api.readNewsLetter())
        }

    }

    return (
        <body data-bs-spy="scroll" data-bs-target="#main_menu">
            <div id="home"></div>
            <Nav />

            {/* First Part*/}
            <section id="about" className="pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 align-self-center about-text">
                            <div className='redLine'></div>
                            <span className="title-md" >BE UP TO DATE</span>
                            <h6 className="title-sm">Check our latest events, training and more</h6>
                            <p className="mb-4 title-p" > Follow Cyshield's  journey and stay up to date with our internal and external news</p>

                        </div>
                        <div className="col-md-6 align-self-center about-img">
                            <img src="img/newsRoom.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            {/*ArticlesButton*/}
            <section id="articleButtons" >
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-md-8">
                            <h2 className="title-md">OUR ARTICLES</h2>

                        </div>
                    </div>
                    <div className='row'>
                        <ul>
                            <li>
                          
                                <button class={!buttontraining ? 'buttonUnclicked' : 'buttonClicked'} id="my-training" onClick={handleClick}>Training</button>
                                <button class={!buttonconferences ? 'buttonUnclicked' : 'buttonClicked'} id="my-Conferences" onClick={handleClick}>Conference</button>
                                <button class={!buttonevents ? 'buttonUnclicked' : 'buttonClicked'} id="my-Events" onClick={handleClick}>Events</button>
                                <button class={!buttonnewsletter ? 'buttonUnclicked' : 'buttonClicked'}  id="my-NewsLetter" onClick={handleClick}>NewsLetter</button>
                                <button class={!buttonsecurity ? 'buttonUnclicked' : 'buttonClicked'}  id="my-SecurityAvisor" onClick={handleClick}>Security Advisor</button>
                            </li>
                        </ul> </div>
                    <div className="row">



                    </div>
                </div>
            </section>

            {/*Articles*/}
            <section id="recentArticles" className="pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 align-self-center about-img">
                          
                            <img src={`${config.backend.baseurl + mostRecentImage}`} alt="" />
    
                        </div>
                        <div className="col-md-6  align-self-center  about-text">
                            <span  >Announcement</span>
                            <p className="mb-2 title-p" >{announcement.Title}</p>
                            <p className='date'>{announcement.Creator}</p>

                            <span >Most Recent</span>
                            <p className="mb-2 title-p" >{mostrecent.Title}</p>
                            <p className='date'>{mostrecent.Creator}</p>

                            <span  >Trending Now</span>
                            <p className="mb-2 title-p" >{trending.Title}</p>
                            <p className='date'>{trending.Creator}</p>
                        </div>
                        

                    </div>
                </div>
            </section>



            {/* Articles Data Part*/}
            <section id="articles" className="pb-5">
                <div className="container">
                  
                    <div className="row scroll">
                    {article ? article.map((articles) => (
                        <div className="col-md-4 ">
                            <div className=" border-0 p-4">
                                <div className='card-img'>
                                    <img src={`${config.backend.baseurl + articles.attributes.image.data[0].attributes.url}`} alt="" />
                                </div>
                                <div className="card-body ">
                                    <h4 className="title-p">{articles.attributes.Title}</h4>
                                    <p className="title-p">  {articles.attributes.SubTitle}</p>
                                <p className='date'> {articles.attributes.Creator}</p>
                            <div className='gradientLine'></div>
                                </div>
                            </div>
                        </div>
                      )) : ""}
                       
                    </div>
                </div>
            </section>

            {/*NewsLetter*/}
            <section id="newsletter" className="py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8">
                            <div className='whiteLine'></div>
                            <h6 >KNOW US BETTER</h6>
                            <h4>Want to know more?</h4>
                            <p className='title-p'>Signup now and get the latest news about our company and more</p>
                            <button type="button" className="btn"  onClick={navigateToAboutus}>Know more about us</button>
                        </div>

                    </div>
                </div>
            </section>


            {/*Footer*/}
            <Footer />

        </body>
    );
}