import * as React from 'react';
import Nav from '../sharedComponents/navbar'
import Footer from '../sharedComponents/footer'
import * as api from '../Apis/subscribers';
import config from '../config/config.json'
export default function Blog(props) {
    const [email, setEmail] = React.useState({});
    const sendEmail = () => {
        let newObj = {
            data: email
        }

        
        if (Object.keys(newObj.data).length === 0) {
            newObj.data={}
        } else {
            api.createSubscriber(newObj)
        }


        // window.location.reload(true)

    }
    return (
        <body data-bs-spy="scroll" data-bs-target="#main_menu">

            {/*NewsLetter*/}
            <section id="newsletter" className="py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8">
                            <div className='whiteLine'></div>
                            <h6 >Get more news with us</h6>
                            <h4>  Want To Discover More ?</h4>
                            <p className='title-p'> Signup now and get the latest news about our company and more</p>
                            <div class="input-group mb-3" >
                                <input type="text" class="form-control" className='Discover_Button' placeholder="Enter Your Email" aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={e => setEmail({ ...email, Email: e.target.value })} required />
                                <div class="input-group-append">
                                    <button class="Discover_Button_check" type="button" onClick={sendEmail}>Send</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </body>
    );
}