import * as React from 'react';
import Nav from '../sharedComponents/navbar'
import Footer from '../sharedComponents/footer'
import BlueCard from '../sharedComponents/blueCard'
export default function CyberSecurity(props) {


    return (
        <body data-bs-spy="scroll" data-bs-target="#main_menu">
            <div id="home"></div>
            <Nav />

            {/* First Part*/}
            <section id="about" className="pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 align-self-center about-text">
                            <div className='redLine'></div>
                            <span className="title-md" >DISCOVER OUR SERVICES</span>
                            <h6 className="title-sm">Cyber Security</h6>

                        </div>

                        <div className="col-md-6 align-self-center about-img">
                            <img src="img/Security.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>


            {/* Penetration Testing Part*/}
            <section id="computerVision" className="pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6  ">
                            <h2 className="title-md" >Penetration Testing</h2>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                    Penetration testing involves discovering and validating all possible
                                    vulnerabilities for a given scope from the standpoint of the agreed upon  user. <br />
                                    <br />
                                    roles of the application within a defined time frame. Vulnerabilities are exploited individually or combined to demonstrate breach-ability and  <br />illustrate risks. <br />
                                    <br />
                                    During this type of engagements, tests are done to simulate attacks  <br />from the <br />
                                    standpoint of each role included in the scope (e.g. anonymous, external user,<br />
                                    internal user, administrator, etc.).<br />
                                    <br />
                                    The goal of the exercise is making sure that all security features are  <br />working<br />
                                    as intended and no functionality can be abused to compromise any of<br />
                                    the information security aspects (Confidentiality, Integrity, Availability,<br />
                                    Accountability (CIAA)).<br />
                                    <br />
                                    Vulnerabilities are validated using proof-of-concept exploits and risk-evaluated according to a standard risk assessment methodology.<br /></p>
                            </div>



                            <h2 className="title-md" > Cyshield provides penetration testing for the following:</h2>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                    Web/ Mobile Mobile/Desktop Application Penetration Test.</p>
                            </div>

                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                    Internal/ External Infrastructure Penetration Test.</p>
                            </div>

                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                    VOIP Infrastructure Penetration Test.</p>
                            </div>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                    Wireless Infrastructure Penetration Test.</p>
                            </div>

                        </div>

                        <div className="col-md-6 about-img">
                            <img src="img/Security2.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>


            {/* Security Assesment Part*/}
            <section id="computerVision" className="pb-5">
                <div className="container">
                    <div className="row">

                        <div className="col-md-6 about-img">
                            <img src="img/Security3.png" alt="" />
                        </div>
                        <div className="col-md-6  ">
                            <h2 className="title-md" > Security Assesment</h2>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                    Security assessment involves examining and assessing the current  <br /> position of our customers against all possible  vulnerabilities for a given <br />scope of examination. It is typically performed with full access to the <br />client and full scope of the application.<br />
                                    <br />
                                    This type of engagements checks the security of the application against <br /> security baselines, standards and industry best practices to produce a <br />holistic view of the application security posture.<br />
                                    <br />
                                    Vulnerabilities are risk-evaluated according to a standard risk assessment <br /> methodology.
                                    <br></br></p>
                            </div>



                            <h2 className="title-md" >Cyshield provides security assessment for the following:</h2>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                    Web Application security assessment.</p>
                            </div>

                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                    Mobile Application security assessment.</p>
                            </div>

                           
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                    Desktop Application security assessment.</p>
                            </div>

                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                    Internal Infrastructure and Phishing Readiness.</p>
                            </div>


                        </div>


                    </div>
                </div>
            </section>


            {/*  Secure Source Code Review Part*/}
            <section id="GRC" >

                <div className="container">
                    <div className="row">
                        <div className="col-md-12  ">
                            <h2 className="title-md" > Secure Source Code Review</h2>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>

                                    Cyshield offers secure code review services to review a given scope of an application›s source code  to identify security related weaknesses in the code. A secure code review provides security insights to the development team into security implications of different parts of the code through a mix of <br /> manual and automated inspection of the different parts of the code. Automated tools are used to cover all parts of the code and highlight areas of focus. <br /> Manual review focuses on sensitive and critical areas.<br />
                                    <br />
                                    The reviewer goes over all aspects of information security (CIAA - Confidentiality, Integrity, Availability, and Accountability) and how they are and will <br /> continue to be protected with the expansion of different parts of the application. The goal is to arm developers with information about possible <br />vulnerabilities in the source code, how they can be abused by attackers and provide suggestions for secure coding best practices in relevant areas.
                                    <br /></p>
                            </div>

                        </div>



                    </div>
                </div>
            </section>


            {/*  Infrastructure Configration Review Part*/}
            <section id="NaturalLanguage" className="pb-5 " >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12  ">
                            <h2 className="title-md" >  Infrastructure Configration Review</h2>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                Cyshield offers infrastructure configuration review services to review the IT infrastructure configurations in scope (such as routers, switches, firewalls, <br /> WAFs, IPSs, etc.) against security baselines, standards and industry best practices. <br />
                                        <br />
                                        The goal of the review is making sure that all configurations are set to the intended security level and no functionality can be abused to compromise any <br />of the information security aspects (CIAA - Confidentiality, Integrity, Availability, and Accountability). <br /></p>
                            </div>


                        </div>

                    </div>
                </div>
            </section>

            {/*  Digital Forensics and Incident Response Part*/}
            <section id="GRC" >

                <div className="container">
                    <div className="row">
                        <div className="col-md-12  ">
                            <h2 className="title-md" >Digital Forensics and Incident Response</h2>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                Cyshield’s digital forensics and incident response service helps customers prepare, respond and recover from a cyber breach. The  goal of this type of engagements is primarily assessing and containing damage caused by the breach, helping the customer understand how  it happened and how to prevent it from happening again. The scope varies depending on the incident case from a single machine or device to a full stack of applications, endpoints, servers and network infrastructure.</p>
                            </div>

                        </div>



                    </div>
                </div>
            </section>

            {/*NewsLetter*/}
            <BlueCard />


            {/*Footer*/}
            <Footer />

        </body>
    );
}
