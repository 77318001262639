import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./index.css";

import Home from './components/mainComponents/Home';
import EntryPage from './components/mainComponents/EntryPage';
import AboutUs from './components/mainComponents/aboutUs';
import ContactUs from './components/mainComponents/contactUs';
import Career from './components/mainComponents/career';
import Blog from './components/mainComponents/blog';
import NewsRoom from './components/mainComponents/newsRoom';
import AIandDS from './components/servicesComponents/AI&DS';
import GRC from './components/servicesComponents/GRC';
import RTS from './components/servicesComponents/RTS';
import CYBERSECURITY from './components/servicesComponents/cyberSecurity';
import Software from './components/servicesComponents/softwareEngineer';
import Soc from './components/servicesComponents/SOC';
import CustomBlogView from './components/sharedComponents/customBlogView';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>

    <Routes>
    <Route path="/" element={<EntryPage />} />
      <Route path="home" element={<Home />} />
      <Route path="aboutUs" element={<AboutUs />} />
      <Route path="contactUs" element={<ContactUs />} />
      <Route path="career" element={<Career />} />
      <Route path="blog" element={<Blog />} />
      <Route path="news" element={<NewsRoom />} />
      <Route path="AIDS" element={<AIandDS />} />
      <Route path="grc" element={<GRC />} />
      <Route path="rts" element={<RTS />} />
      <Route path="customblog"  element={<CustomBlogView />} />
      <Route path="cyberSecurity" element={<CYBERSECURITY />} />
      <Route path="software" element={<Software />} />
      <Route path="soc" element={<Soc />} />
    </Routes>
  </BrowserRouter>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
