import axios from 'axios';
import config from '../config/config.json'
import {token} from './token';

const url =`${config.backend.baseurl+'/api/articles?populate=*'}`
export const readArticles= () => axios.get(url,{headers: { 
    'Authorization': token}});

const Training_url =`${config.backend.baseurl+'/api/news-room-trainings?populate=*'}`
export const readTraining= () => axios.get(Training_url,{headers: { 
    'Authorization': token}});


const Conferences_url =`${config.backend.baseurl+'/api/news-room-conferences?populate=*'}`
export const readConferences= () => axios.get(Conferences_url,{headers: { 
    'Authorization': token}});


const Events_url =`${config.backend.baseurl+'/api/news-room-events?populate=*'}`
export const readEvents= () => axios.get(Events_url,{headers: { 
    'Authorization': token}});


const NewsLetter_url =`${config.backend.baseurl+'/api/news-room-news-letters?populate=*'}`
export const readNewsLetter= () => axios.get(NewsLetter_url,{headers: { 
    'Authorization': token}});

const SecurityAdvisor_url =`${config.backend.baseurl+'/api/news-room-security-advisors?populate=*'}`
export const readSecurityAdvisor= () => axios.get(SecurityAdvisor_url,{headers: { 
    'Authorization': token}});






