import * as React from 'react';
import Nav from '../sharedComponents/navbar'
import Footer from '../sharedComponents/footer'
import { useNavigate } from 'react-router-dom';
import * as api from '../Apis/leaderShip';
import config from '../config/config.json'
// import { Carousel } from "react-responsive-carousel";
import Carousel from 'react-material-ui-carousel'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function App(props) {
    const [flag, setflag] = React.useState(false);
    const [leadership, setLeadership] = React.useState([]);
    const [images, setImages] = React.useState([]);
    const navigate = useNavigate();
    var w = window.innerWidth;
    const sliderSettings = {
        dots: true,
        infinite: true,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 5000,
        // centerMode: true,
        speed: 500,
        // rows: 2,
        slidesToShow: 2,
        slidesToScroll: 2,
        dotsClass: 'slick-dots',
        // the magic
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true
            }

        }, {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true
            }
        }, {
            breakpoint: 300,
            settings: "unslick" // destroys slick
        }]
    };


    React.useEffect(() => {
        const fetchData = async () => {
            const result = await api.readLeadership();

            setLeadership(result.data.data)


            const resultImage = await api.readImages();

            setImages(resultImage.data.data)
        }
        fetchData()


    }, [])

    const navigateToCareer = () => {
        window.location.href = 'https://careers.cyshield.com/';
    };
    function UncontrolledExample(props) {
        return (
            <div className="carousel-item ">
                <section id="leaderShip" className="pb-5">
                    <div className="container">
                        <div className="row ">
                            {images ? images.slice(props.start, props.end).map((Image) => (
                                <div className="col-md-4 mb-3">
                                    <div className="card border-0 overflow-hidden">
                                        <img src={`${config.backend.baseurl + Image.attributes.Image.data[0].attributes.url}`} alt="" />
                                    </div>
                                </div>
                            )) : ""}

                        </div>
                    </div>
                </section>

            </div>
        );
    }

    // function SwipeableTextMobileStepper() {
    //     const theme = useTheme();
    //     const [activeStep, setActiveStep] = React.useState(0);
    //     const maxSteps = images.length;
        
    //         const handleNext = () => {
    //             setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //         };
        
    //         const handleBack = () => {
    //             setActiveStep((prevActiveStep) => prevActiveStep - 1);
    //         };
        
    //         const handleStepChange = (step) => {
    //             setActiveStep(step);
    //         };
        
    //         return (
    //         <Box sx={{ maxWidth: 1200, flexGrow: 1 }}>
    //             <Paper
    //                 square
    //                 elevation={0}
    //                 sx={{
    //                     display: 'flex',
    //                     alignItems: 'center',
    //                     height: 50,
    //                     pl: 2,
    //                     bgcolor: 'background.default',
    //                 }}
    //             >
    //             </Paper>
    //             <AutoPlaySwipeableViews
    //                 axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
    //                 index={activeStep}
    //                 onChangeIndex={handleStepChange}
    //                 enableMouseEvents
    //             >
    //             {images.map((step, index) => (
    //                 <div key={step.label}>
    //                 {Math.abs(activeStep - index) <= 2 ? (
    //                     <Box
    //                         component="img"
    //                         sx={{
    //                             height: 255,
    //                             display: 'block',
    //                             maxWidth: 400,
    //                             overflow: 'hidden',
    //                             width: '100%',
    //                         }}
    //                         src={`${config.backend.baseurl + step.attributes.Image.data[0].attributes.url}`}
                            
    //                     />
    //                 ) : null}
    //                 </div>
    //             ))}
    //             </AutoPlaySwipeableViews>
    //             <MobileStepper
    //                 steps={maxSteps}
    //                 position="static"
    //                 activeStep={activeStep}
    //                 nextButton={
    //                     <Button
    //                         size="small"
    //                         onClick={handleNext}
    //                         disabled={activeStep === maxSteps - 1}
    //                     >
    //                     Next
    //                         {theme.direction === 'rtl' ? (
    //                             <KeyboardArrowLeft />
    //                         ) : (
    //                             <KeyboardArrowRight />
    //                         )}
    //                     </Button>
    //                 }
    //                 backButton={
    //                     <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
    //                     {theme.direction === 'rtl' ? (
    //                         <KeyboardArrowRight />
    //                     ) : (
    //                         <KeyboardArrowLeft />
    //                     )}
    //                     Back
    //                     </Button>
    //                 }
    //             />
    //         </Box>
    //         );
    //     }




    return (
        <body data-bs-spy="scroll" data-bs-target="#main_menu">
            <div id="home"></div>
            <Nav />

            {/* First Part*/}
            <section id="about" className="pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 align-self-center about-text">
                            <div className='redLine'></div>
                            <span className="title-md" >KNOW US BETTER</span>
                            <h6 className="title-sm">About Us</h6>
                            <p className="mb-4 title-p" >We believe that every project existing in a digital world is a result of an
                                idea and every idea has a cause.</p>

                        </div>
                        <div className="col-md-6 align-self-center about-img">
                            <img src="img/aboutUs.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            {/* Second Part*/}
            <section id="HomeAbout" className="pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 align-self-center about-img">
                            <img src="img/Home2.png" alt="" />
                        </div>
                        <div className="col-md-6 align-self-center about-text">
                            <div className='redLine'></div>
                            <span  >WHO WE ARE ?</span>

                            <p className="mb-4 title-p" >Cyshield employs world-class Digital professionals with cutting edge
                                knowledge and experience . Our wide experience in the digital space
                                makes us the best choice for your company needs and  our focus on
                                highly specialized areas allows us to deliver superior consultation
                                services that are the best in the market. We are dedicated to work
                                hard in order to escort our clients to their ultimate goals.</p>

                        </div>

                    </div>
                </div>
            </section>

            {/* why us Part*/}
            <section id="service" className="pb-5">
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-md-8 offset-md-2 text-center">
                            <h2 className="title-md">WHY US ?</h2>
                            <p className='title-p'>Our mindset is being the success partner in the business journey of our clients,
                                exploit our resources of top notch people and endless innovative ideas escorting our customers to their ultimate goals</p>
                        </div>
                    </div>
                    <div className="row">


                        <div className="col-md-12 pt-2">
                            <div className=" border-0 p-4">
                                <div className="card-body" onMouseOver={(e) => { setflag(true) }} onMouseLeave={(e) => { setflag(false) }}>

                                    {flag ? <img src="img/MissionImageHover.png" /> : <img src="img/MissionImage.png" />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* DataCounting Part*/}
            <section id="service" className="pb-5">
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-md-8 offset-md-2 text-center">
                            <h2 className="title-md">HIGHLIGHTS</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className=" border-0 p-4 pt-0">
                                <div className="card-body" >

                                    <img src="img/DataCounting.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Internationa Atmoshpere Part*/}
            <section id="leaderShip" >
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-md-8 offset-md-2 text-center">
                            <h2 className="title-md">OUR INTERNATIONAL ATMOSPHERE</h2>
                            <p className='title-p'> Our mindset is being the success partner in the business journey of our clients,
                                exploit our resources of top notch people and endless innovative ideas escorting our customers to their ultimate goals</p>
                        </div>
                    </div>

                </div>
            </section>
            <section id="sliderAbout">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <Slider {...sliderSettings}>
                                {
                                    images.map( (item) => 
                                        <>
                                            <img key={`${item.id}`} src={`${config.backend.baseurl + item.attributes.Image.data[0].attributes.url}`} alt={item.alt} />
                                            <h6 className="legend center pt-1">{item.attributes.Caption}</h6>
                                        </>    
                                    )
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>

            {/* Discover us Part*/}
            <section id="service" className="pb-5">
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-md-8 offset-md-2 text-center">
                            <h2 className="title-md">DISCOVER US</h2>

                        </div>
                    </div>
                    <div className="row">


                        <div className="col-md-12 pt-2">
                            <div className=" border-0 p-4">
                                <div className="card-body">
                                    <img src="img/Discoverus.png" alt="" />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Our History Part*/}
            <section id="service" className="pb-5">
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-md-8 offset-md-2 text-center">
                            <h2 className="title-md">OUR HISTORY</h2>
                            <p className='title-p'>Established in <span class="Secure-cyshield">2016</span>, Cyshield is a leading digital services company with specializations in Artificial Intelligence, Cyber Security, Data Science, IoT and Software Engineering. Cyshield works with large companies including financial, media, healthcare and governmental organizations.</p>
                        </div>
                    </div>
                    <div className="row">


                        <div className="col-md-12 pt-2">
                            <div className=" border-0 p-4">
                                <div className="card-body">
                                    <img src="img/History.png" alt="" />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/*NewsLetter*/}
            <section id="newsletter" className="py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8">
                            <div className='whiteLine'></div>
                            <h6 >Join our team</h6>
                            <h4>If you are looking to grow your career </h4>
                            <p className='title-p'></p>

                            <button type="button" className="btn" onClick={navigateToCareer}>Join Now </button>

                        </div>

                    </div>
                </div>
            </section>


            {/*Footer*/}
            <Footer />

        </body>
    );
}