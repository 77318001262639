import * as React from 'react';
import Nav from '../sharedComponents/navbar'
import Footer from '../sharedComponents/footer'
import BlueCard from '../sharedComponents/blueCard'
export default function SOC(props) {


    return (
        <body data-bs-spy="scroll" data-bs-target="#main_menu">
            <div id="home"></div>
            <Nav />

            {/* First Part*/}
            <section id="about" className="pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 align-self-center about-text">
                            <div className='redLine'></div>
                            <span className="title-md" >DISCOVER OUR SERVICES</span>
                            <h6 className="title-sm">Secure Infrastructure</h6>

                        </div>

                        <div className="col-md-6 align-self-center about-img">
                            <img src="img/Soc.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            {/*Product Implementation Part*/}
            <section id="AIFullImage" className="pb-5">
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-md-12  text-left mb-5">
                            <h2 className="title-md">Product Implementation</h2>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p'>  At Cyshield, we employ subject matter experts who cover a vast range of technologies including but not limited to the market norms (Firewalls, IPS/IDS, SIEM, Proxy, VPNs (SSL/IPSEC/others), Network Access Controls, Endpoint Security, Sandboxes, Application Firewalls, Load Balancers, etc.).
                                    <br></br>
                                    <br></br>
                                    Our team of gurus are experienced at creating and implementing custom built solutionsbased on multivendor integration as well as leveraging open-source technologies to enhance the overall architecture.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='backgroundSOC'>
                    <div className='container'>
                        <div className="row ">
                            <div className="col-md-6 pt-2 text-left ">
                                <h2 className="title-md">Architecture Review</h2>
                                <img class="Arrow" src="img/Arrow.png" />
                                <div className='box'>
                                    <p className='title-p '>
                                        Sophisticated attack vectors require suitable controls to be in place in order to protect the organization’s networks from advanced threats.
                                        Our team of experts perform a balanced security review for the network security infrastructure of organizations taking into consideration the threat landscape, optimal performance, and usability.
                                        <br></br>
                                        <br></br>
                                        Cyshield experts are ready to audit the current infrastructure in order to find
                                        weaknesses and threat vectors that affect the organization’s security footprint.</p>
                                </div>

                                <h2 className="title-md">Solution Design</h2>
                                <img class="Arrow" src="img/Arrow.png" />
                                <div className='box'>
                                    <p className='title-p '>
                                        Network security design and architecture have often been overlooked by organizations looking to solve their security concerns by investing in new technologies. This frequently leads to an abundance of security technologies but without their proper utilization. Network security architecture leverages the organization’s resources while network security design implements these concepts in the most effective way possible.
                                        <br></br>
                                        <br></br>
                                        A well-planned and constructed network security design is critical to minimizing the security gaps in the infrastructure which are often targeted by attackers. This is essential to controlling access to critical data within the organization. We believe that “One size doesn’t fit all”.
                                        <br></br>
                                        <br></br>
                                        Cyshield’s world-class experts work together with our customers along the study, design and delivery journey of security projects to ensure that our solutions were tailored to our customer requirements.</p>
                                </div>
                            </div>


                        </div>


                    </div>





                </div>

            </section>


            {/* Configuration Audit Part*/}
            <section id="GRC" >
                <div className="container">
                    <div className="row">
                        <div className="col-md-6  ">
                            <h2 className="title-md" >Configuration Audit</h2>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                    One of the main security risks that threaten an organization’s security is misconfiguration. Device configuration reviews are carried out to verify that devices are configured correctly to minimize the associated risks and to provide the required security posture.
                                    <br></br>
                                    <br></br>
                                    Based on information security standards and best practices, our security experts can identify weaknesses in running configurations and provide a detailed remediation plan in order to improve the security stance of the organization.</p>
                            </div>

                            <h2 className="title-md" > Policy Tuning</h2>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                    Having a firewall, proxy, or WAF policy tuned is a big challenge for organizations as it requires an in-depth understanding of the technology as well as a solid grasp of business flows, applications and requirements.
                                    <br></br>
                                    <br></br>
                                    With the support of key personnel at the contracting organization, our security experts have the required skill sets to tune and optimize the current organization’s security policies to achieve its security goals.</p>
                            </div>
                        </div>

                        <div className="col-md-6  ">
                            <h2 className="title-md" >Managed Service</h2>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p '>
                                    We offer a managed service option, allowing organizations to free up
                                    IT resources for other business-critical tasks. Based on a team of security experts and support engineers, our service provides you with the highest level of security and performance around the clock.
                                    Our managed service provides organizations with continuous health monitoring, policy tuning, configuration management, and weekly reporting.</p>
                            </div>

                            <h3 className="title-md" > Technologies</h3>
                            <div className="row">
                                <div className="col-md-6  ">
                                    <img class="Arrow" src="img/Arrow.png" />
                                    <div className='box'>
                                        <p className='title-p '>
                                            Firewalls.</p>
                                    </div>

                                    <img class="Arrow" src="img/Arrow.png" />
                                    <div className='box'>
                                        <p className='title-p '>
                                        Secure Application Delivery.</p>
                                    </div>

                                    <img class="Arrow" src="img/Arrow.png" />
                                    <div className='box'>
                                        <p className='title-p '>
                                        Application Firewalls.</p>
                                    </div>

                                    <img class="Arrow" src="img/Arrow.png" />
                                    <div className='box'>
                                        <p className='title-p '>
                                        IPS/IDS.</p>
                                    </div>

                                    <img class="Arrow" src="img/Arrow.png" />
                                    <div className='box'>
                                        <p className='title-p '>
                                        SIEM Platforms.</p>
                                    </div>

                                    <img class="Arrow" src="img/Arrow.png" />
                                    <div className='box'>
                                        <p className='title-p '>
                                        Proxies.</p>
                                    </div>
                                </div>

                                <div className="col-md-6  ">
                                    <img class="Arrow" src="img/Arrow.png" />
                                    <div className='box'>
                                        <p className='title-p '>
                                        Endpoint Security.</p>
                                    </div>

                                    <img class="Arrow" src="img/Arrow.png" />
                                    <div className='box'>
                                        <p className='title-p '>
                                        VPNs (IPSEC/SSL/Others).</p>
                                    </div>

                                    <img class="Arrow" src="img/Arrow.png" />
                                    <div className='box'>
                                        <p className='title-p '>
                                        Network Access Controls.</p>
                                    </div>

                                    <img class="Arrow" src="img/Arrow.png" />
                                    <div className='box'>
                                        <p className='title-p '>
                                        Sandboxes.</p>
                                    </div>

                                    <img class="Arrow" src="img/Arrow.png" />
                                    <div className='box'>
                                        <p className='title-p '>
                                        Load Balancers.</p>
                                    </div>

                                    
                                  
                                </div>

                            </div>







                        </div>


                    </div>
                </div>
            </section>


             {/*   SOC - as - a - Service Part*/}
             <section id="AIFullImage" className="pb-5">
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-md-12  text-left mb-5">
                            <h2 className="title-md">SOC - as - a - Service</h2>
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <p className='title-p'> The evolving threat landscape and compliance requirements have pushed many organizations to establish their own security operations center to continuously monitor and respond to threats targeting their services and business environments.
                            <br></br>
                            <br></br>
                            SOC-as-a-service (Managed SOC) is suitable for organizations that want to have an in-depth understanding of what is happening within their network and be able to respond immediately to possible threats while not having to deal with the required operational overhead.
                            <br></br>
                            <br></br>
                            At Cyshield, our SOC primarily focuses on the detection of potential threats within the organization’s networks, ranging from hackers and malware to employees who accidentally or intentionally seek access to confidential information that is not within their security clearance.</p>
                            </div>
                        </div>
                    </div>
                </div>

             

            </section>


            {/* Why CYSHIELD SOC as a Service? Part*/}
            <section id="computerVision" className="pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7  ">
                            <h2 className="title-md" > Why CYSHIELD SOC as a Service?</h2>

   
                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <h3 className='title-p '>
                                24x7 Operations:</h3>
                                <p className='title-p '> We have 24x7 SOC experts working around the clock to immediately
                                        detect and respond to any threats.</p>
                            </div>

                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <h3 className='title-p '>
                                Experts:</h3>
                                <p className='title-p '>Our SOC engineers include experts with experience in a multitude of
                                        technologies which can detect and fend off sophisticated attacks.</p>
                            </div>


                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <h3 className='title-p '>
                                High Security Levels:</h3>
                                <p className='title-p '> Our SOC is designed to enhance the organization’s security level.
                                        We offer various security models, from a low entry model to a fullservice
                                        SOC.</p>
                            </div>

                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <h3 className='title-p '>
                                Mature Process:</h3>
                                <p className='title-p '>Our team follows a mature operational model that supports flexible
                                        operations, faster response, accurate detection/protection, as well
                                        as a clear path of communication.</p>
                            </div>

                            <img class="Arrow" src="img/Arrow.png" />
                            <div className='box'>
                                <h3 className='title-p '>
                                Top-Notch Technologies:</h3>
                                <p className='title-p '>We employ the most advanced technologies to help our experts
                                        detect and protect against sophisticated attacks.</p>
                            </div>
                        </div>

                        <div className="col-md-5 align-self-center about-img">
                            <img src="img/SOC2.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>

             {/*   SOC - as - a - Service Part*/}
             <section id="AIFullImage" className="pb-5">
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-md-12  text-left mb-5">
                            
                            <img class="Arrow" src="img/redArrow.png" />
                            <div className='box'>
                           <h3 className="title-md">Cyshield helps organizations in building their own SOC as well as provide SOC-as-a-service with various operational models that are tailored to every organization’s needs.</h3>
                            </div>
                        </div>
                    </div>
                </div>

             

            </section>


            {/*NewsLetter*/}
            <BlueCard />


            {/*Footer*/}
            <Footer />

        </body>
    );
}