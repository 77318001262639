import Home from './components/mainComponents/Home'
import './Custom.scss'
import './App.css'
function App() {
  return (
     <Home />
  );
}

export default App;
