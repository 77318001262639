import axios from 'axios';
import config from '../config/config.json'
import {token} from './token';

const url =`${config.backend.baseurl+'/api/leaderships?populate=%2A'}`
export const readLeadership = () => axios.get(url,{headers: { 
    'Authorization': token}});

const AboutusImages =`${config.backend.baseurl+'/api/about-us-images?populate=%2A'}`
export const readImages = () => axios.get(AboutusImages,{headers: { 
    'Authorization': token}});

// import axios from 'axios';
// import config from '../config/config.json'
// const url =`${config.backend.baseurl+'/api/leaderships?populate=%2A'}`
// export const readLeadership = () => axios.get(url);

// const AboutusImages =`${config.backend.baseurl+'/api/about-us-images?populate=%2A'}`
// export const readImages = () => axios.get(AboutusImages);

