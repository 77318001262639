import axios from 'axios';
import config from '../config/config.json'
import {token} from './token';
const qs = require('qs');

var query;
const url = `${config.backend.baseurl + '/api/careers'}`
export const readCareers = () => axios.get(url);


/*
const query = qs.stringify({
    filters: {
        Job_type: {
        $eq: 'Software Developer',
      },
    },
  }, {
    encodeValuesOnly: true, // prettify URL
  });
*/
export const readCareerss = async ({ Job_type, Department, keyword }) => {
    query = qs.stringify({
        filters: {

            $and: [
                {

                    Job_type: {
                        $eq: Job_type

                    }
                },
                {
                    Department: {
                        $eq: Department

                    }
                },
            ],

            $or: [

                {
                    Job_Title: {
                        $containsi: keyword
                    }
                }
            ]
        },
    }, {
        encodeValuesOnly: true, // prettify URL
    });
    const urll = `${config.backend.baseurl + `/api/careers?${query}`}`
    const readCareersbyJob_type = await axios.get(urll,{headers: { 
        'Authorization': token}});
    return readCareersbyJob_type
}


