import * as React from 'react';
import {
    Facebook, Twitter,
    Linkedin, Envelope, Telephone, GeoAlt, ArrowUpSquareFill
} from 'react-bootstrap-icons';

export default function Footer(props) {
    return (
        <body data-bs-spy="scroll" data-bs-target="#main_menu">

            <div className='row'>
                <img src='./img/gradientLine.png' />
            </div>
            {/*Footer*/}
            <footer id="footer" className="pt-5 pb-2">
                <div className="container">
                    <div className="row gx-5">
                        <div className="col-md-2">
                            <img src="img/logo.png" alt="" className='footer-image' />


                        </div>
                        <div className="col-md-4">
                            <h3>Contact Information</h3>
                            <ul className="list-unstyled">
                                <li className="mb-2"><Envelope /> info@cyshield.com</li>
                                <li className="mb-2"><Telephone />+2 22699305</li>
                                <li className="mb-2"><GeoAlt /> Plot 13 Block 1258,<br /><div className='address'>Sheraton Heliopolis, Cairo, Egypt</div></li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <h3>Our Website</h3>
                            <div className='row'>
                                <div className='col-6'>
                                    <ul className="list-unstyled">
                                        <li className="mb-2  footer-aboutUs"><a href="aboutUs"><img src='./img/footerIcon.png' width='15px' />About us</a></li>
                                        <li className="mb-2"><a href="contactUs"><img src='./img/footerIcon.png' width='15px' />Contact us</a></li>


                                    </ul>
                                </div>
                                <div className='col-6'>
                                    <ul className="list-unstyled">

                                        <li className="mb-2"><a href="cyberSecurity"><img src='./img/footerIcon.png' width='15px' /> Services</a></li>
                                        <li className="mb-2"><a href="https://careers.cyshield.com/" target="_blank" ><img src='./img/footerIcon.png' width='15px' /> Careers</a></li>

                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-2 footerStyle">
                            <h3>Get In Touch</h3>
                            <ul className="list-unstyled ">
                                <li className="mb-2"><a href="https://www.facebook.com/CyShield" target="_blank"> <Facebook /></a> </li>
                                <li className="mb-2"><a href="https://twitter.com/cyshieldcompany?s=11&t=WrxLhcE1R_KN1Ur3hDH49w" target="_blank"><Twitter /> </a></li>
                                <li className="mb-2"><a href="https://www.linkedin.com/company/cyshield/" target="_blank"><Linkedin /></a></li>
                            </ul>
                        </div>

                    </div>
                </div>
                <hr />
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <p className="copy">Cyshield For Technology,©2022. All Rights Reserved</p>
                        </div>
                    </div>

                </div>
            </footer>

        </body>
    );
}