import * as React from 'react';
import * as api from '../Apis/newsRoom';
import config from '../config/config.json'
import Nav from '../sharedComponents/navbar'
import Footer from '../sharedComponents/footer'
import { useLocation, useNavigate } from 'react-router-dom';


export default function CustomBlogView(props) {

    const navigate = useNavigate();
    const location = useLocation();
    let article = location.state.data.attributes
    return (
        <body data-bs-spy="scroll" data-bs-target="#main_menu">
            <div id="home"></div>
            <Nav />

            <div className='customBlogClass' dangerouslySetInnerHTML={{__html: article.Paragraph}} />
            {/*Footer*/}
            <Footer />

        </body>
    );
}