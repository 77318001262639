import * as React from 'react';
import Nav from '../sharedComponents/navbar'
import Footer from '../sharedComponents/footer'
import BlueCard from '../sharedComponents/blueCard'
import * as api from '../Apis/blog';
import config from '../config/config.json'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import CustomBlogView from '../sharedComponents/customBlogView';
import {Routes, Route, useNavigate, Link} from 'react-router-dom';
export default function Blog(props) {

    const navigate = useNavigate();
    const [mostrecent, setMostrecent] = React.useState([]);
    const [mostrecent_image, setMostrecent_image] = React.useState([]);
    const [article, setArticle] = React.useState([]);
    const [announcement, setAnnouncement] = React.useState([]);
    const [trending, setTrending] = React.useState([]);
    const [buttonarticle, setButtonarticle] = React.useState(false);
    const [buttontechnology, setButtontechnology] = React.useState(false);
    const [buttonbranding, setButtonbranding] = React.useState(false);
    const [buttonteamwork, setButtonteamwork] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [body, setBody] = React.useState([]);
    const [model_image, Model_image] = React.useState([]);
    const [openModal, setOpenModal] = React.useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    let date = new Date();
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        maxHeight: "17rem",
        overflowY: "scroll"
    };


    // TODO: to be removed
    const navigateToCustomView = (props) => {
        <Link to="/customblog" state={{ data: props }} className="link">
            
        </Link>
        // navigate('/customblog', {replace: true}, { state: { props: article }});
        // <CustomBlogView props={props}/>
        // window.location.href = 'https://cyshield.com/e077d6c3-adff-49a1-afc3-71e10140f95c';
        console.log(props);
    };

    const handleOpen = (body) => {

        setBody(body.attributes)
        Model_image(body.attributes.image.data[0].attributes.url)
        setOpen(true);
    }

    const handleClose = () => setOpen(false);

    React.useEffect(() => {
        var result = api.readArticles();
        fetchData(api.readArticles())
    }, [])

    var fetchData = async (result) => {
        result = await result
        for (let i = 0; i < result.data.data.length; i++) {

            if (result.data.data[i].attributes.card_type.data.attributes.Type === 'Announcement') {
                setAnnouncement(result.data.data[i].attributes)
            }

            else if (result.data.data[i].attributes.card_type.data.attributes.Type === 'Trending Now') {
                setTrending(result.data.data[i].attributes)
            }

            setMostrecent(result.data.data[i].attributes)
            setMostrecent_image(result.data.data[i].attributes.image.data[0].attributes.url)
        }
        setArticle(result.data.data)
    }

    const handleClick = event => {
        if (event.currentTarget.id === 'my-technology') {
            setButtontechnology(true)
            setButtonarticle(false)
            setButtonbranding(false)
            setButtonteamwork(false)
            fetchData(api.readTechnology())
        }
        else if (event.currentTarget.id === 'my-article') {
            setButtonarticle(true)
            setButtontechnology(false)
            setButtonbranding(false)
            setButtonteamwork(false)
            fetchData(api.readArticles())
        }
        else if (event.currentTarget.id === 'my-branding') {
            setButtonbranding(true)
            setButtonarticle(false)
            setButtontechnology(false)
            setButtonteamwork(false)
            fetchData(api.readBranding())
        }
        else if (event.currentTarget.id === 'my-Teamwork') {
            setButtonteamwork(true)
            setButtonarticle(false)
            setButtontechnology(false)
            setButtonbranding(false)
            fetchData(api.readTeamwork())
        }

    }


    function MyModal() {

        return (
            <div>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='modal-box '>
                        {/*  Most Recent*/}

                        <div className="d-flex flex-wrap flex-column  ">
                            <div className='row scrollModel'>
                                <div className='p-2 '>
                                    <h2 class="Blog_Tex_Card">
                                        {body.Title}
                                    </h2>
                                </div>


                                <div className='p-2'>
                                    <h2 class="Blog_subtitle2_Card">
                                        {body.Creator}
                                    </h2>
                                </div>


                            </div>
                            <div className='p-2 mt-5'>
                                <Typography className='Blog2_paragraph2' variant="h6" component="h2">
                                    {body.Paragraph}
                                </Typography>
                            </div>

                        </div>
                        {/* End */}

                    </Box>
                </Modal>
            </div>
        );
    }


    return (
        <body data-bs-spy="scroll" data-bs-target="#main_menu">
            <div id="home"></div>
            <Nav />

            {/* First Part*/}
            <section id="about" className="pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 align-self-center about-text">
                            <div className='redLine'></div>
                            <span className="title-md" >DISCOVER OUR BLOG</span>
                            <h6 className="title-sm">We blog because we have something to say</h6>
                            <p className="mb-4 title-p" >"Blogging does not mean creating but giving more value to the users accordingly."</p>

                        </div>
                        <div className="col-md-6 align-self-center about-img">
                            <img src="img/Blog.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            {/*ArticlesButton*/}
            <section id="articleButtons" >
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-md-8">
                            <h2 className="title-md">OUR ARTICLES</h2>

                        </div>
                    </div>
                    <div className='row'>
                        <ul>
                            <li>
                                <button className={!buttonarticle ? 'buttonUnclicked' : 'buttonClicked'} id="my-article" onClick={handleClick}>Articles</button>
                                <button className={!buttontechnology ? 'buttonUnclicked' : 'buttonClicked'} id="my-technology" onClick={handleClick}>Technology</button>
                                <button className={!buttonbranding ? 'buttonUnclicked' : 'buttonClicked'} id="my-branding" onClick={handleClick}>Branding</button>
                                <button className={!buttonteamwork ? 'buttonUnclicked' : 'buttonClicked'} id="my-Teamwork" onClick={handleClick}>TeamWork</button>
                            </li>
                        </ul> </div>
                    <div className="row">



                    </div>
                </div>
            </section>

            {/*Articles*/}
            <section id="recentArticles" className="pb-5">
                <div className="container">
                    <div className="row">
                        {mostrecent_image !== null ?
                            <div className="col-md-6 align-self-center about-img">

                                <img src={`${config.backend.baseurl + mostrecent_image} `} alt="" />

                            </div>
                            : ""}

                        {announcement !== null ?
                            < div className="col-md-6  align-self-center  about-text">
                                <span  >Announcement</span>
                                <p className="mb-2 title-p cursor" >{announcement.Title}</p>
                                <p className='date'>{announcement.Creator}</p>

                                <span  >Most Recent</span>
                                <p className="mb-2 title-p " >{mostrecent.Title}</p>
                                <p className='date'>{mostrecent.Creator}</p>

                                <Modal
                                    open={openModal}
                                    onClose={handleCloseModal}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 500,
                                    }}
                                >
                                    <Fade in={openModal}>
                                        <Box sx={modalStyle}>
                                            <Typography id="modal-modal-title" component="h6">
                                                {new Date(announcement.createdAt).toDateString()}
                                            </Typography>
                                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                                {announcement.Title}
                                            </Typography>
                                            <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                                                {announcement.SubTitle}
                                            </Typography>
                                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                                {announcement.Paragraph}
                                            </Typography>
                                        </Box>
                                    </Fade>
                                </Modal>

                                <span  >Trending Now</span>
                                <p className="mb-2 title-p" >{trending.Title}</p>
                                <p className='date'>{trending.Creator}</p>
                            </div>
                            : ""}
                    </div>
                </div>
            </section>

            {/* Articles Data Part*/}
            <section id="articles" className="pb-5">
                <div className="container">

                    <div className="row scroll">
                        {article ? article.map((articles) => (
                            <div className="col-md-4 ">
                                <div className=" border-0 p-4">
                                    <div className='card-img'>
                                        <img src={`${config.backend.baseurl + articles.attributes.image.data[0].attributes.url}`} alt="" />
                                    </div>
                                    <div className="card-body ">
                                            <Link to="/customblog" state={{ data: articles }} className="link" style={{textDecoration: 'none'}}>
                                                <h4 className="title-p">
                                                        {articles.attributes.Title}
                                                </h4>
                                            </Link> 
                                        <p className="title-p" >  {articles.attributes.SubTitle}</p>
                                        <p className='date'>  {articles.attributes.Creator}</p>
                                        <div className='gradientLine'></div>
                                    </div>
                                </div>
                            </div>
                        )) : ""}


                    </div>
                </div>
            </section>

            {/*NewsLetter*/}
            <BlueCard />


            {/*Footer*/}
            <Footer />

        </body >
    );
}